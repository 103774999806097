import React, { FC, useState, useEffect } from 'react';

interface TextRevealProps {
  text: string;
  interval: number;
  repeatInterval?: number;
}

const characters = 'abcdefghijklmnopqrstuvwxyz&§!-_?%$£=+/@#כּכיטחזוהדגבּבאךתשׂשׁשרקצפּפעסנמלץףןם';
const charactersLength = characters.length;

const generateRandomText = (originalText: string): string => 
  originalText
    .split('')
    .map(char => (char === ' ' ? ' ' : characters.charAt(Math.floor(Math.random() * charactersLength))))
    .join('');

const shuffleArray = (array: number[]): number[] => {
  const shuffledArray = [...array];
  const shuffledArrayLength = shuffledArray.length;

  for (let i = shuffledArrayLength - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
};

const TextReveal: FC<TextRevealProps> = ({ text, interval, repeatInterval }) => {
  const [revealedText, setRevealedText] = useState(generateRandomText(text));
  const [indices, setIndices] = useState<number[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [repeat, setRepeat] = useState(false);

  useEffect(() => {
    const indicesArray = shuffleArray(Array.from(Array(text.length).keys()));

    setIndices(indicesArray);
    setRevealedText(generateRandomText(text));
    setCurrentIndex(0);
  }, [text, repeat]);

  useEffect(() => {
    if (currentIndex >= indices.length) return;

    const intervalId = setInterval(() => {
      const index = indices[currentIndex];
      const currentChar = text[index];

      setRevealedText((prevText) =>
        prevText.substring(0, index) + currentChar + prevText.substring(index + 1)
      );
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }, interval);

    return () => clearInterval(intervalId);
  }, [text, interval, indices, currentIndex]);

  useEffect(() => {
    if (repeatInterval && repeatInterval > 0) {
      const repeatIntervalId = setInterval(() => {
        setRepeat((prev) => !prev);
      }, repeatInterval);

      return () => clearInterval(repeatIntervalId);
    }
  }, [repeatInterval]);

  return <span aria-label={text}>{revealedText}</span>;
};

export default TextReveal;
