import PropTypes from 'prop-types';
import React from 'react';

import TextReveal from '../TextReveal';

import './style.css';

interface BlockquoteProps {
  content: string;
}

const Blockquote = ({ content }: BlockquoteProps) => (
  <blockquote>
    <p><TextReveal text={content} interval={25} repeatInterval={8000} /></p>
  </blockquote>
);

export default Blockquote;
