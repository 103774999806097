import React, { FC } from 'react';

import TextReveal from '../TextReveal';

interface HighlightTextProps {
  text: string;
}

const HighlightText: FC<HighlightTextProps> = ({text}) => 
    <strong><TextReveal text={text} interval={30} /></strong>;

export default HighlightText;
