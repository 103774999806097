import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import * as ReactIcons from 'react-icons/fa';

import './style.css';

interface SocialNext {
  url: string;
  icon: string;
}

const SocialIcons = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            socialNext {
              url
              icon
            }
          }
        }
      }
    `,
  );

  return (
    <p>
      <ul>
        {site.siteMetadata.socialNext.map(({ url, icon }: SocialNext) => {
          const reactIcon = (ReactIcons as any)[icon];

          return (
            <li key={url}>
              <a key={url} aria-label={url} href={url}>
                {React.createElement(reactIcon)}
              </a>
            </li>
          );
        })}
      </ul>
    </p>
  );
};

export default SocialIcons;
