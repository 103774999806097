import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SocialIcons from '../SocialIcons';

import './style.css';

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  );

  const year = new Date().getFullYear();

  return (
    <footer>
      <p>
        My Manifesto:{' '}
        <a href="http://www.reactivemanifesto.org/">The Reactive</a> and{' '}
        <a href="http://manifesto.softwarecraftsmanship.org/">
          Software Craftsmanship
        </a>
      </p>
      <p>
        &copy; {year} - {site.siteMetadata.title} - Simple Design by me ツ
      </p>
      <SocialIcons />
    </footer>
  );
};

export default Footer;
