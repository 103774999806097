import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import TextReveal from '../TextReveal';

import './style.css';

const Skills = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            skills
          }
        }
      }
    `,
  );

  return (
    <div className="skills">
      <ul>
        {site.siteMetadata.skills.map((skill: string) => (
          <li key={skill}>
            <TextReveal text={skill} interval={25} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Skills;
