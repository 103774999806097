import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Blockquote from '../components/Blockquote';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/Seo';
import HighlightText from '../components/HighlightText'

const IndexPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              twitter {
                title
                link
              }
              linkedin {
                title
                link
              }
            }
          }
        }
      }
    `,
  );

  return (
    <Layout>
      <SEO title="Home" lang="en" />
      <Header />

      <div>
        <p>
          I&apos;m french <HighlightText text="Software Architect & Craftsman" /> living
          in Paris, working in the field for more than 20 years. I’ve had the
          opportunity to work and learn with a large variety of technology. I
          was a <a href="https://en.wikipedia.org/wiki/Demoscene">demomaker</a>{' '}
          (<a href="https://en.wikipedia.org/wiki/Atari">atari</a>,{' '}
          <a href="https://en.wikipedia.org/wiki/Amstrad">amstrad</a>,{' '}
          <a href="https://en.wikipedia.org/wiki/Amiga">amiga</a> and{' '}
          <a href="https://en.wikipedia.org/wiki/Personal_computer">PC</a>) in the 90s -{' '}
          <a href="https://en.wikipedia.org/wiki/Razor_1911">Razor 1911,</a>{' '}
          (0x309 / 777 in <a href="https://en.wikipedia.org/wiki/Hexadecimal">hexadecimal</a> :-)).{' '}
          I spent a large portion of my youth
          on{' '}
          <a href="https://en.wikipedia.org/wiki/Bulletin_board_system">BBS</a>{' '}
          and{' '}
          <a href="https://en.wikipedia.org/wiki/Internet_Relay_Chat">IRC</a>.
          I’ve learned various programming languages over the years, such as Basic, C,
          C++, Prolog, Lisp, PHP, Pascal, Ruby, Python, Java, C#, Go, Rust, JavaScript/TypeScript,
          Solidity and so on. I’ve a good mastery of continuous integration / delivery
          (k8s, docker, AWS, GCP, OVH...). When I want to learn something new, I
          start by practicing first, rather than learning the theory. For me,
          learning new technologies is like learning how to ride a bike. You
          practice, fail, get back up and repeat.
        </p>

        <Blockquote content="Computer enthusiast since the age of 9 years." />

        <p>
          Throughout my career I’ve had many technical responsibilities. I’m
          also responsible for many projects and{' '}
          <HighlightText text="innovations including advanced technologies" /> for
          various{' '}
          <a href="https://www.linkedin.com/in/rgaidot/">software companies</a>.
          I’m creator and contributor of{' '}
          <HighlightText text="open source software since 1996" />. I’m very interested
          about innovative and complex challenges. I’ve a passion for building{' '}
          <HighlightText text="highly disruptive" /> new products. I like to work in
          challenging and creative environments and I’m really interested in
          working with people highly motivated to tackle each challenge as
          doable. I’m a polyglot developer, architect and devops.
        </p>

        <p>
          I’ve participate in the creation,{' '}
          <HighlightText text="technological development" /> and{' '}
          <HighlightText text="board member" /> of various{' '}
          <HighlightText text="European Startups" />. My technology exploits have been
          featured on big media and blogs.
        </p>

        <Blockquote
          content="I spend a lot of my time creating software and consulting as a
            researcher, looking for new challenges."
        />

        <p>
          I’m constant to look, read and think about what{' '}
          <HighlightText text="comes next" /> and I stay focused on the horizon, in
          anticipation of what’s coming up next.
        </p>

        <p>
          I spend some of my free time{' '}
          <HighlightText text="advising in technology-driven startup companies" />. I
          think, I’ve an good ability to decipher seemingly complex new
          technologies and translate them into a simple set of business
          benefits.
        </p>

        <p>
          Elsewhere, you can find or ping me on a variety of social websites
          like{' '}
          <a href={site.siteMetadata.social.twitter.link}>
            {site.siteMetadata.social.twitter.title}
          </a>{' '}
          or{' '}
          <a href={site.siteMetadata.social.linkedin.link}>
            {site.siteMetadata.social.linkedin.title}
          </a>
          .
        </p>
      </div>

      <Footer />
    </Layout>
  );
};

export default IndexPage;
