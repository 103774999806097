import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Avatar from '../Avatar';
import Skills from '../Skills';
import TextReveal from '../TextReveal';

import './style.css';

const Header = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            skills
          }
        }
      }
    `,
  );

  return (
    <header>
      <div className="flex-header-container">
        <div>
          <Avatar />
        </div>
        <div className="me">
          <h1 title={site.siteMetadata.title}>
            <TextReveal text={site.siteMetadata.title} interval={100} repeatInterval={6000} />
          </h1>
        </div>
      </div>
      <Skills />
    </header>
  );
};

export default Header;
